import React, { ChangeEvent, useContext, useEffect, useRef } from 'react'
import { Navigate } from 'react-router';
import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { Language } from '../../enum/Language';
import { useDictionary } from '../../hooks/useDictionary';
import { Api, UIError } from '../../util/Api';
import { Logger } from '../../util/Logger';
import CzechLexeme from '../../valueobject/CzechLexeme';
import EnglishLexeme from '../../valueobject/EnglishLexeme';
import { LexemePairWithLang } from '../../valueobject/IdPairWithLanguage';
import { CUSTOM_BUTTON_LABEL, CustomButton } from '../generic/CustomButton';
import { InputArea } from '../generic/InputArea';
import { InputLanguageButton } from '../generic/InputLanguageButton';
import { InputWithLanguageFlag } from '../generic/InputWithlanguageFlag';
import { MainPageContent } from '../MainPageContent';
import { Navigation, NavigationItem } from '../Navigation';
import { AppContext } from '../RootComponent';

const CUSTOM_BUTTON_CLASS = "icon material-symbols-outlined customButton";
const ADD_CONFIRMATION_TIMEOUT_MS = 2_000;

const pulsingRecordButton = keyframes`
    0%, 100% { 
        background-color: red; 
    }
    50% { 
        background-color: #302f2d;
    }
`;

const noPulse = keyframes``;

interface SpeechButtonProps {
    $isRecording?: boolean,
    $isSmallVersion?: boolean
}

const SpeechButton = styled.button<SpeechButtonProps>`
    border-radius: 50%;
    background-color: ${(props) => props.$isSmallVersion ? "rgba(0,0,0,0)" : "#3b3938"};
    color: #ffffff;
    font-size: ${(props) => props.$isSmallVersion ? "min(10vw, 10vh)" : "min(25vw, 10vh)"};
    padding: ${(props) => props.$isSmallVersion ? "min(3vw, 1.5vh)" : "min(6vw, 3vh)"};
    animation: ${(props) => props.$isRecording ? pulsingRecordButton : noPulse} 1s infinite;
    border: 1vw white solid;
`;

const TranslateButton = styled.button`
    font-size: 6vw;
    padding: 4vw 8vw;
    font-weight: bold;
`;

const AddToDictionaryButton = styled.button`
    font-size: 6vw;
    padding: 4vw 8vw;
    font-weight: bold;
`;

const CloseTypingViewButton = styled.button`
    font-size: 9vw;
    padding: 4vw 8vw;
    color: white;
    background-color: #302f2d;
    border: none;
    position: relative;
    top: 4vh;
    right: -5vw;
    font-weight: 200;
`;

const RecordOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #302f2d;
    padding: 20px
`;

const CloseOverlayButton = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: white;
    color: #3b3938;
    border-radius: 50%;
    width: 50px;
    height: 50px;
`;

const CenteredContent = styled.div`
    text-align: center;
    height: 80%;
`;

const InstructionText = styled.p`
    color: #cfc6b2;
    padding: 40px 25%;
    font-size: 8vw;
`;

const RecognisedText = styled.div`
    color: white;
    font-size: 24px;
    height: 30%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const RecordingMessage = styled.p`
    color: white;
    font-size: 24px;
    padding: 20px;
`;

enum CurrentView {
    ENTRY_POINT,
    RECORDING,
    TYPING,
    TRANSLATING,
    CONFIRMATION
}

enum RedirectTarget {
    NONE,
    ADD,
    TEST,
    RECENT,
    SETTINGS
}

export interface AddNuProps {
    editing?: LexemePairWithLang | undefined;
}

export const AddNu = (props: AddNuProps) => {
    const dictionary = useDictionary();
    const context = useContext(AppContext);
    const navigate = useNavigate();
    const [translationText, setTranslationText] = React.useState("");
    const [inputLanguage, setInputLanguage] = React.useState<Language>(Language.ENGLISH);
    const [currentView, setCurrentView] = React.useState<CurrentView>(CurrentView.ENTRY_POINT);
    const [redirectTarget, setRedirectTarget] = React.useState<RedirectTarget>(RedirectTarget.NONE);
    const [isTranslationLoading, setIsTranslationLoading] = React.useState(false);

    // Input text state needs to be accessible in callbacks, so we need to use a ref
    const [inputText, _setInputText] = React.useState("");
    const inputTextRef = useRef<string>(inputText);
    const setInputText = (text: string) => {
        inputTextRef.current = text;
        _setInputText(text);
    }

    // Recording state needs to be accessible in callbacks, so we need to use a ref
    const [isRecording, _setIsRecording] = React.useState(false);
    const isRecordingRef = useRef<boolean>(isRecording);
    const setIsRecording = (isRecording: boolean) => {
        isRecordingRef.current = isRecording;
        _setIsRecording(isRecording);
    }

    let recognition = useRef<any>(null);

    useEffect( () => {
        // TODO - figure out why const doesn't work in this case... hoisting??
        // @ts-ignore
        var SpeechRecognition = SpeechRecognition || webkitSpeechRecognition
        recognition.current = new SpeechRecognition();
        recognition.current.continuous = true;
        recognition.current.interimResults = true;
        recognition.current.lang = inputLanguage;
        recognition.current.maxAlternatives = 1;
        recognition.current.addEventListener("result", onSpeechRecognitionServiceResult);
        recognition.current.addEventListener("start", onSpeechRecognitionServiceStart);
        recognition.current.addEventListener("end", onSpeechRecognitionServiceEnd);

        if (props.editing) {
            const {czechLexeme, englishLexeme, langToFocus} = props.editing;
            setInputText(langToFocus === Language.ENGLISH ? props.editing.englishLexeme.text : props.editing.czechLexeme.text);
            setTranslationText(langToFocus === Language.ENGLISH ? props.editing.czechLexeme.text : props.editing.englishLexeme.text);
            setInputLanguage(langToFocus);
            setCurrentView(CurrentView.TRANSLATING);
        } else {
            context.setCurrentlyEditingLexemePair(undefined);
        }

        return () => {
            recognition.current.removeEventListener("result", onSpeechRecognitionServiceResult);
            recognition.current.removeEventListener("start", onSpeechRecognitionServiceStart);
            recognition.current.removeEventListener("end", onSpeechRecognitionServiceEnd);
        }
    }, [])

    useEffect(() => {
        (async () => {
            if (currentView === CurrentView.CONFIRMATION) {
                setTimeout(complete, ADD_CONFIRMATION_TIMEOUT_MS);
            }
            if (currentView === CurrentView.TRANSLATING && !props.editing) {
                setIsTranslationLoading(true);
                await translate();
                setIsTranslationLoading(false);
            }
        })()
    }, [currentView]);

    const complete = () => {
        setInputText("");
        setTranslationText("");
        if (props.editing) {
            navigate('/recent');
        } else {
            setCurrentView(CurrentView.ENTRY_POINT)
        }
    }

    const translate = async () => {
        const [translation, error] = await Api.postTranslation(
            inputText,
            inputLanguage,
            inputLanguage === Language.CZECH ? Language.ENGLISH : Language.CZECH,
        );
        if (error) {
            Logger.error('[translate]:', JSON.stringify(error));
            return "";
        }
        setTranslationText(translation);
        setCurrentView(CurrentView.TRANSLATING);
    }

    const onSpeechRecognitionServiceResult = (event: SpeechRecognitionEvent) => {
        let output = ""
        for (let i = 0; i < event.results.length; i++) {
            output += event.results[i][0].transcript;
        }
        if (isRecordingRef.current) setInputText(output);
    }

    const onSpeechRecognitionServiceStart = () => {
        setIsRecording(true);
    }

    const onSpeechRecognitionServiceEnd = () => {
        setIsRecording(false);
    }

    const toggleInputLang = () => {
        const newLang = inputLanguage === Language.ENGLISH ? Language.CZECH : Language.ENGLISH;
        setInputLanguage(newLang);
        recognition.current.lang = newLang === Language.CZECH ? "cs-CZ" : "en-US";
    }

    const onRecordOverlayClose = () => {
        recognition.current.stop();
        setInputText("");
        setIsRecording(false);
        setCurrentView(CurrentView.ENTRY_POINT);
    }

    const onRecordButtonClicked = () => {
        if (!isRecording) {
            recognition.current.start();
            setCurrentView(CurrentView.RECORDING);
        } else {
            recognition.current.stop();
            if (inputTextRef.current === "") {
                setCurrentView(CurrentView.ENTRY_POINT);
            } else {
                setCurrentView(CurrentView.TRANSLATING);
            }
        }
    }

    const onDeleteButtonCLicked = async () => {
        if (props.editing) {
            if (confirm(dictionary.CONFIRM_DELETE_LEXEME_PAIR)) {
                const czId = props.editing.czechLexeme.id;
                const enId = props.editing.englishLexeme.id;
                if (!props.editing.czechLexeme.id || !props.editing.englishLexeme.id) {
                    Logger.error('[AddLexeme.onDeleteButtonClicked]:', UIError.INVALID_LEXEME_PAIR);
                    return;
                }
                const [, error] = await Api.deleteLexemePair(czId!, enId!);
                // TODO handle error
            }
            navigate('/recent');
        }
    }

    const onSaveOrUpdateButtonClicked = async () => {
        const enText = inputLanguage === Language.ENGLISH ? inputText : translationText;
        const enLexeme = new EnglishLexeme(enText, props.editing ? props.editing.englishLexeme.id : null);

        const czText = inputLanguage === Language.CZECH ? inputText : translationText;
        const czLexeme = new CzechLexeme(czText, props.editing ? props.editing.czechLexeme.id : null);
        const [, error] = await Api.postLexemePair({enLexeme, czLexeme, pairingNotes: ""});
        if (error) {
            Logger.error('[onSaveButtonClicked]:', JSON.stringify(error));
            return;
        }
        setInputText("");
        setTranslationText("");
        setCurrentView(CurrentView.CONFIRMATION);

    }

    const onInputTextChanged = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setInputText(event.target.value);
    }

    const onClearInputTextButtonClicked = () => {
        setInputText("")
    }

    const onClearTranslationTextButtonClicked = () => {
        setTranslationText("")
    }

    const onTranslationTextChanged = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setTranslationText(event.target.value);
    }

    const onInputAreaSelectedForTyping = () => {
        setCurrentView(CurrentView.TYPING);
    }

    const onCloseTypingView = () => {
        setInputText("");
        setTranslationText("");
        setCurrentView(CurrentView.ENTRY_POINT);
    }

    if (redirectTarget !== RedirectTarget.NONE) {
        switch (redirectTarget) {
            case RedirectTarget.ADD:
                return <Navigate to="/add" />;
            case RedirectTarget.TEST:
                return <Navigate to="/tests" />;
            case RedirectTarget.RECENT:
                return <Navigate to="/recent" />;
            case RedirectTarget.SETTINGS:
                return <Navigate to="/settings" />;
            default:
                return <Navigate to="/add" />;
        }
    }

    return (
        <>
            <h1>{props.editing ? dictionary.PAGETITLE_EDIT : dictionary.PAGETITLE_ADD}</h1>
            <MainPageContent>

                {currentView === CurrentView.ENTRY_POINT ? <>
                    <InputLanguageButton language={inputLanguage} onClick={toggleInputLang}/>
                    <CenteredContent>
                        <InputArea
                            autoFocus={false}
                            onClick={onInputAreaSelectedForTyping}
                            onChange={() => {}}
                            onClear={onClearInputTextButtonClicked}
                            placeholder={inputLanguage === Language.ENGLISH ? 'Type here' : 'Napište zde'}
                            value={inputText}
                        />
                        <SpeechButton onClick={onRecordButtonClicked} className={CUSTOM_BUTTON_CLASS}>
                            {CUSTOM_BUTTON_LABEL.mic}</SpeechButton>
                    </CenteredContent>
                </> : null}

                {currentView === CurrentView.TYPING ? <>
                    <InputLanguageButton language={inputLanguage} onClick={toggleInputLang}/>
                    <CenteredContent>
                        <InputArea
                            autoFocus={true} onClick={onInputAreaSelectedForTyping}
                            placeholder={inputLanguage === Language.ENGLISH ? 'Type here' : 'Napište zde'}
                            value={inputText}
                            onChange={onInputTextChanged}
                            onClear={onClearInputTextButtonClicked}
                        />
                        <div className="buttonRow">
                            <SpeechButton onClick={onRecordButtonClicked} className={CUSTOM_BUTTON_CLASS}
                                          $isSmallVersion={true}>{CUSTOM_BUTTON_LABEL.mic}</SpeechButton>

                            {inputText !== '' && <TranslateButton onClick={translate}>{dictionary.BUTTON_TRANSLATE}</TranslateButton>}
                            {inputText === '' && <CloseTypingViewButton onClick={onCloseTypingView} >X</CloseTypingViewButton>}
                        </div>
                    </CenteredContent>
                </> : null}

                {currentView === CurrentView.RECORDING ? <RecordOverlay>
                    <CloseOverlayButton
                        onClick={onRecordOverlayClose}
                        className={CUSTOM_BUTTON_CLASS}>{CUSTOM_BUTTON_LABEL.close}</CloseOverlayButton>
                    <CenteredContent>
                        <RecognisedText>{
                            inputText.length > 50 ? inputText.substring(0, 100) + '...' : inputText
                        }</RecognisedText>
                        <SpeechButton
                            onClick={onRecordButtonClicked}
                            className={CUSTOM_BUTTON_CLASS}
                            $isRecording={true}>{CUSTOM_BUTTON_LABEL.mic}</SpeechButton>
                        <RecordingMessage>{dictionary.RECORDING_INSTRUCTIONS}</RecordingMessage>
                    </CenteredContent>
                </RecordOverlay> : null}

                {currentView === CurrentView.TRANSLATING ? <>
                    <CenteredContent>
                        <InputWithLanguageFlag
                            inputText={inputText}
                            inputLanguage={inputLanguage}
                            onInputTextChanged={onInputTextChanged}
                            onClearInputTextButtonClicked={onClearInputTextButtonClicked}
                        />

                        <hr/>

                        <InputWithLanguageFlag
                            inputLanguage={inputLanguage === Language.ENGLISH ? Language.CZECH : Language.ENGLISH}
                            inputText={translationText}
                            onInputTextChanged={onTranslationTextChanged}
                            onClearInputTextButtonClicked={onClearTranslationTextButtonClicked}
                            placeholder={isTranslationLoading ? dictionary.PLACEHOLDER_TRANSLATION_LOADING : dictionary.PLACEHOLDER_TRANSLATION}
                        />

                        <div className="buttonRow">
                            <div>
                                <CustomButton onClick={complete} label={CUSTOM_BUTTON_LABEL.cancel} />
                                {props.editing && <CustomButton label={CUSTOM_BUTTON_LABEL.delete} onClick={onDeleteButtonCLicked} />}
                            </div>
                            {inputText !== '' && translationText === '' && <TranslateButton onClick={translate}>{dictionary.BUTTON_TRANSLATE}</TranslateButton>}
                            {inputText !== '' && translationText !== '' && <AddToDictionaryButton onClick={onSaveOrUpdateButtonClicked}>{props.editing ? dictionary.BUTTON_UPDATE : dictionary.BUTTON_SAVE}</AddToDictionaryButton>}
                        </div>
                    </CenteredContent>
                </> : null}

                {currentView === CurrentView.CONFIRMATION ? <>
                    <CenteredContent>
                        <InstructionText>{props.editing ? dictionary.CONFIRM_WORD_UPDATED : dictionary.CONFIRM_WORD_ADDED}</InstructionText>
                        <button onClick={() => setRedirectTarget(RedirectTarget.RECENT)}>{dictionary.BUTTON_RECENT}</button>
                    </CenteredContent>
                </> : null}
            </MainPageContent>
            <Navigation activeItem={props.editing ? NavigationItem.NONE : NavigationItem.ADD} />
        </>
    )
}
