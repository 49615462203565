import React from "react";

export enum CUSTOM_BUTTON_LABEL {
    mic = "mic",
    close = "close",
    cancel = "cancel",
    recent = "reorder",
    tests = "psychology_alt",
    add = "add",
    delete = "delete",
    settings = "settings",
}

export interface CustomButtonProps {
    label: string;
    onClick?: () => void;
}

export const CustomButton = (props: CustomButtonProps) => {
    const buttonStyle = {fontSize: "min(9vw, 9vh)"};
    return (
        <button className="icon material-symbols-outlined customButton" style={buttonStyle} onClick={props.onClick}>
            {props.label}
        </button>
    );
}
