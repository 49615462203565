import * as React from 'react';
import { useDictionary } from '../../hooks/useDictionary';
import { UserData } from '../../util/Api';
import { Progress, Result } from './Test';
import { Navigate } from 'react-router';

interface IResultStats {
    worse: number;
    noProgress: number;
    improved: number;
}

interface IResultsProps {
    testResults: Array<Result>;
    userData: UserData | null;
}

export const Results = (props: IResultsProps) => {

    const dictionary = useDictionary();
    const [backToTestsButtonClicked, setBackToTestsButtonClicked] = React.useState(false);

    if (backToTestsButtonClicked)
        return <Navigate to="/tests" />;

    const getStats = (): IResultStats => {

        let worse = 0;
        let noProgress = 0;
        let improved = 0;

        props.testResults.forEach((item) => {
            switch (item.progress) {
                case Progress.worse:
                    worse++;
                    break;

                case Progress.improved:
                    improved++;
                    break;

                default:
                    noProgress++;
            }
        });

        return {
            worse: worse,
            noProgress: noProgress,
            improved: improved
        } as IResultStats;
    }

    const renderTitle = () => {
        return (<h2>{dictionary.RESULTS_SUMMARY_TITLE}</h2>);
    }

    const renderSummary = () => {
        const stats = getStats();
        return (
            <div className="summary">
                <div className="summaryItem">{dictionary.RESULTS_SUMMARY_ITEM_IMPROVED}: {stats.improved}</div>
                <div className="summaryItem">{dictionary.RESULTS_SUMMARY_ITEM_NO_PROGRESS}: {stats.noProgress}</div>
                <div className="summaryItem">{dictionary.RESULTS_SUMMARY_ITEM_WORSE}: {stats.worse}</div>
                <div className="summaryItem">{dictionary.RESULTS_SUMMARY_CURRENT_STREAK}: {props.userData?.currentStreakLength}</div>
                <div className="summaryItem">{dictionary.RESULTS_SUMMARY_HIGH_STREAK}: {props.userData?.highStreakLength}</div>
                <div className="summaryItem">{dictionary.RESULTS_SUMMARY_HIGH_STREAK_END_DATE}: {new Date(props.userData?.highStreakEnd!).toDateString()}</div>
                <div className="summaryItem">{dictionary.RESULTS_SUMMARY_TESTS_COMPLETED_COUNT}: {props.userData?.testsCompletedCount}</div>
            </div>
        );
    }

    const renderBackToTestsButton = () => {
        return (
            <button onClick={() => setBackToTestsButtonClicked(true)}>
                {dictionary.BUTTON_BACK_TO_TESTS}
            </button>
        );
    }

    return (
        <div>
            {renderTitle()}
            {renderSummary()}
            {renderBackToTestsButton()}
        </div>
    );
};
