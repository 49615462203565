import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useDictionary } from '../hooks/useDictionary';
import { CUSTOM_BUTTON_LABEL } from './generic/CustomButton';
import { NavButton } from './generic/NavButton';

export enum NavigationItem {
    NONE,
    ADD,
    RECENT,
    TESTS,
    SETTINGS
}

const NavigationBar = styled.div`
    background-color: white;
    color: grey;
    text-align: center;
    padding: 1.3vh;
    position: absolute;
    bottom: 4vh;
    width: 86vw;
    left: 50%;
    margin-left: -43vw;
    border-radius: 10vh;
    overflow: hidden;
    justify-content: space-between;
`;

export interface NavigationProps {
    activeItem: NavigationItem
}

export const Navigation = (props: NavigationProps) => {
    const dictionary = useDictionary();
    return (
        <NavigationBar className="navigation">
            <Link to="/add"><NavButton label={dictionary.PAGETITLE_ADD} customButtonLabel={CUSTOM_BUTTON_LABEL.add} activeNav={props.activeItem === NavigationItem.ADD} /></Link>
            <Link to="/tests"><NavButton label={dictionary.PAGETITLE_TESTS} customButtonLabel={CUSTOM_BUTTON_LABEL.tests} activeNav={props.activeItem === NavigationItem.TESTS} /></Link>
            <Link to="/recent"><NavButton label={dictionary.PAGETITLE_RECENT} customButtonLabel={CUSTOM_BUTTON_LABEL.recent} activeNav={props.activeItem === NavigationItem.RECENT} /></Link>
            <Link to="/settings"><NavButton label={dictionary.PAGETITLE_SETTINGS} customButtonLabel={CUSTOM_BUTTON_LABEL.settings} activeNav={props.activeItem === NavigationItem.SETTINGS} /></Link>
        </NavigationBar>
    );
}
