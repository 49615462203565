import React, { useContext } from 'react';
import { Navigate } from 'react-router';
import { Api, AppError } from '../../util/Api';
import { AddNu } from '../add/AddNu';
import { AppContext } from '../RootComponent';

export const Edit = () => {

    const [error, setError] = React.useState<AppError | null>(null);
    const context = useContext(AppContext);

    return (
        <>
            {error && <Navigate to={`/errorPage/${error}`}/>}
            {!error && <AddNu editing={context.currentlyEditingLexemePair} />}
        </>
    );
}
