import React, { useEffect } from 'react';
import { Navigate } from 'react-router';
import styled from 'styled-components';
import { ApiMethod, ApiPath } from '../../design/Path';
import { useDictionary } from '../../hooks/useDictionary';
import { GoogleAuth } from '../../util/GoogleAuth';
import LoaderUtil from '../../util/LoaderUtil';
import { MainPageContent } from '../MainPageContent';
import { Navigation, NavigationItem } from '../Navigation';

const ButtonBar = styled.div`
    margin-top: 25px;
    margin-bottom: 25px;
`;

const DeleteAllUserDataButton = styled.button`
    background-color: red;
`;

const StyledInput = styled.input`
    font-size: 1.5em;
    width: 3.5em;
`;

export const DeleteAllUserData = () => {
    const dictionary = useDictionary();
    const [deleteConfirmed, setDeleteConfirmed] = React.useState<boolean>(false);
    const [exportCsvSelected, setExportCsvSelected] = React.useState<boolean>(false);
    const [deleted, setDeleted] = React.useState<boolean>(false);
    const [errorDeleting, setErrorDeleting] = React.useState<boolean>(false);
    const [startAgainClicked, setStartAgainClicked] = React.useState<boolean>(false);

    const inputRef = React.useRef<HTMLInputElement>(null);

    useEffect(() => {
        (async () => {if (deleteConfirmed) {
            const [, error] = await LoaderUtil.request<void>(ApiPath.DELETE_ALL_DATA, ApiMethod.DELETE);
            if (error) {
                setErrorDeleting(true);
                return;
            }
            setDeleted(true);
        }})();
    }, [deleteConfirmed]);

    const onDeleteClicked = () => {
        if (inputRef.current && inputRef.current.value !== 'delete') {
            return;
        }
        setDeleteConfirmed(true)
    };

    if (exportCsvSelected) return <Navigate to="/export-csv" />;
    if (startAgainClicked) return <Navigate to="/" />;
    if (errorDeleting) return <Navigate to="/errorPage/ErrorDeletingAllData" replace={true} />;

    return (
        <>
            <h1>{dictionary.PAGETITLE_DELETE_ALL_DATA}</h1>
            {!deleted &&
                <MainPageContent>
                    <p>{dictionary.TEXT_DELETE_ALL_DATA_CONFIRMATION}</p>
                    <p>{dictionary.TEXT_DELETE_ALL_DATA_CONFIRMATION_2}</p>
                    <ButtonBar>
                        <button
                            onClick={() => setExportCsvSelected(true)}>{dictionary.BUTTON_EXPORT_CSV}</button>
                    </ButtonBar>
                    <p>{dictionary.TEXT_DELETE_ALL_DATA_CONFIRMATION_3}</p>
                    <StyledInput ref={inputRef}></StyledInput>
                    <ButtonBar>
                        <DeleteAllUserDataButton
                            onClick={onDeleteClicked}>{dictionary.BUTTON_DELETE_ALL_USER_DATA}</DeleteAllUserDataButton>
                    </ButtonBar>

                </MainPageContent>
            }

            {deleted &&
                <MainPageContent>
                    <p>{dictionary.TEXT_DATA_DELETED}</p>
                    <button
                        onClick={() => setStartAgainClicked(true)}>{dictionary.BUTTON_START_AGAIN}</button>
                    <button
                        onClick={() => GoogleAuth.signOut().then(() => setStartAgainClicked(true))}>{dictionary.BUTTON_SIGN_OUT}</button>
                </MainPageContent>
            }
            <Navigation activeItem={NavigationItem.SETTINGS}/>
        </>
    );
}
