import * as React from 'react';
import { useEffect, useRef } from 'react';
import { Navigate } from 'react-router';
import { useParams } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { TestType } from '../../enum/TestType';
import { useDictionary } from '../../hooks/useDictionary';
import { Api, AppError, UserData } from '../../util/Api';
import { getClientDate } from '../../util/Date';
import { ILexeme } from '../../valueobject/LexemeBase';
import LexemePair from '../../valueobject/LexemePair';
import { Language } from '../../enum/Language';
import { Familiarity } from '../../enum/Familiarity';
import { EmptyDictionary } from '../generic/EmptyDictionary';
import { LanguageIdentifier } from '../generic/LanguageIdentifier';
import { MainPageContent } from '../MainPageContent';
import { Navigation, NavigationItem } from '../Navigation';
import { Results } from './Results';

export enum Progress {
    worse,
    none,
    improved
}

enum TestStatus {
    notStarted,
    inProgress,
    ended,
    emptyDictionary
}

export type Result = {
    lexemePair: LexemePair;
    progress: Progress;
}

type TestParams = {
    type: string;
    length: string;
    languageToTest: string;
};

const FamiliarityButton = styled.button`
    font-size: 8vh;
    margin: 4vw;
`;

const pulsingRevealButton = keyframes`
    0%, 100% { 
        background-color: #d99a1c; 
        color: #463905;
    }
    50% { 
        background-color: #463905;
        color: #d99a1c;
    }
`;

const RevealButton = styled.button`
    padding: 2vh 6vw;
    font-size: 4vh;
    color: #463905;
    margin: 10vh auto;
    display: block;
    animation: ${pulsingRevealButton} 3s infinite;
`;

export const Test = () => {

    const [status, setStatus] = React.useState(TestStatus.notStarted);
    const [lexemes, setLexemes] = React.useState<Array<LexemePair>>([]);
    const [currentIndex, setCurrentIndex] = React.useState(0);
    const [, setCurrentAnswers] = React.useState<ILexeme[]>([]);
    const [showButtonClicked, setShowButtonClicked] = React.useState(false);
    const [error, setError] = React.useState<AppError | null>(null);
    const [userData, setUserData] = React.useState<UserData | null>(null);

    const params = useParams<TestParams>();
    const dictionary = useDictionary();
    const results = useRef<Result[]>([]);

    useEffect( () => {
        loadTestLexemes();
        loadCurrentAnswers();
    },[]);

    useEffect( () => {
        loadCurrentAnswers();
    }, [currentIndex])

    useEffect(() => {
        (async () => {
            if (status === TestStatus.ended) {
                const clientDate: string = getClientDate(new Date());
                const [userData, error] = await Api.postTestComplete(clientDate);
                if (error) {
                    setError(error);
                    return;
                }
                setUserData(userData);
            }
        })()
    }, [status]);

    const markFamiliarity = async (familiarity: Familiarity) => {
        const lexemePair = lexemes[currentIndex];
        const czId = lexemePair.czechLexeme.id;
        const enId = lexemePair.englishLexeme.id;

        if (!czId || !enId) {
            return;
        }
        const result = {
            lexemePair: lexemePair,
            progress: hasImproved(lexemePair.familiarity, familiarity)
        }
        results.current.push(result);

        const [, error] = await Api.putLexemePairFamiliarity(czId, enId, familiarity);

        if (error) {
            setError(error);
        } else {
            if (currentIndex === (lexemes.length - 1)) {
                setStatus(TestStatus.ended);
            } else {
                setCurrentIndex(currentIndex + 1);
                setShowButtonClicked(false);
            }
        }
    }

    const hasImproved = (before: Familiarity, after: Familiarity): Progress => {

        switch (before) {
            case Familiarity.UNKNOWN:
                return (after !== Familiarity.UNKNOWN) ? Progress.improved : Progress.none;

            case Familiarity.FAMILIAR:
                switch (after) {
                    case Familiarity.UNKNOWN:
                        return Progress.worse;
                    case Familiarity.FAMILIAR:
                        return Progress.none;
                    default:
                        return Progress.improved;
                }

            default:
                return (after !== Familiarity.KNOWN) ? Progress.worse : Progress.none;
        }
    }

    const loadTestLexemes = async () => {
        const [result, error] = await Api.getLexemeBatchForTest(
            params.type as TestType,
            parseInt(params.length as string),
        );
        if (error) {
            setError(error);
        } else {
            const status = (result.length > 0) ? TestStatus.inProgress :
                TestStatus.emptyDictionary;
            setLexemes(result);
            setStatus(status);
        }
    }

    const loadCurrentAnswers = async () => {
        let sourceText;
        let sourceLang;
        if (!lexemes[currentIndex]) return;
        if (params.languageToTest === Language.ENGLISH) {
            sourceText = lexemes[currentIndex].czechLexeme.text;
            sourceLang = Language.CZECH;
        } else {
            sourceText = lexemes[currentIndex].englishLexeme.text;
            sourceLang = Language.ENGLISH;
        }
        const [result, error] = await Api.getAllTranslations(sourceText, sourceLang);
        if (error) {
            setError(error);
        } else {
            setCurrentAnswers(result);
        }
    }

    const renderEmptyDictionary = () => {
        return (<EmptyDictionary/>);
    }

    const renderCurrentLexeme = () => {
        return(
            <>
                {renderQuestionText()}
                {!showButtonClicked && renderRevealButton()}
                {showButtonClicked && renderHiddenSection()}
            </>
        );
    }

    const renderHiddenSection = () => {
        const answerText = params.languageToTest === Language.ENGLISH ?
            lexemes[currentIndex].englishLexeme.text :
            lexemes[currentIndex].czechLexeme.text;

        const language = params.languageToTest === Language.ENGLISH ? Language.ENGLISH : Language.CZECH;

        return (
            <>
                <div className="answerText">
                    {renderLanguageIdentifier(language)}
                    <div className="text fade-in-text">{answerText}</div>

                </div>
                {renderFamiliarityButtons()}
            </>
        );
    }

    const renderFamiliarityButtons = () => {
        return(
            <div className="familiarityButtons">
                {renderFamiliarityButton(Familiarity.UNKNOWN, "sentiment_sad", dictionary.BUTTON_RATE_AS_UNKNOWN)}
                {renderFamiliarityButton(Familiarity.FAMILIAR, "sentiment_content", dictionary.BUTTON_RATE_AS_FAMILIAR)}
                {renderFamiliarityButton(Familiarity.KNOWN, "sentiment_excited", dictionary.BUTTON_RATE_AS_KNOWN)}
            </div>
        );
    }

    const renderFamiliarityButton = (familiarity: Familiarity, label: string, title: string) => {
        return (
            <FamiliarityButton
                className={`customButton  material-symbols-outlined familiarity ${familiarity}`}
                onClick={() => {
                    markFamiliarity(familiarity);
                }}
                title={title}
            >{label}
            </FamiliarityButton>
        );
    }

    const renderQuestionText = () => {
        const questionText = params.languageToTest === Language.CZECH ?
            lexemes[currentIndex].englishLexeme.text :
            lexemes[currentIndex].czechLexeme.text;

        const language = params.languageToTest === Language.ENGLISH ? Language.CZECH : Language.ENGLISH;

        return (
            <div className="questionText">
                {renderLanguageIdentifier(language)}
                <div className="text">{questionText}</div>
            </div>
        );
    }

    const renderRevealButton = () => {
        return(
            <RevealButton onClick={() => setShowButtonClicked(true)}>
                {dictionary.BUTTON_REVEAL_ANSWER}
            </RevealButton>);
    }

    const renderResults = () => {
        return(<Results testResults={results.current} userData={userData} />);
    }

    const renderLanguageIdentifier = (language: Language) => {
        return <LanguageIdentifier language={language} $left={"3vw"} $top={"3vw"} />;
    }

    if (error) {
        return (<Navigate to={`/errorPage/${error}`} replace={true}/>);
    }
    return (
        <>
            <h1>{dictionary.PAGETITLE_TESTING}</h1>
            <MainPageContent>
                {status === TestStatus.inProgress && renderCurrentLexeme()}
                {status === TestStatus.ended && renderResults()}
                {status === TestStatus.emptyDictionary && renderEmptyDictionary()}
            </MainPageContent>
            <Navigation activeItem={NavigationItem.TESTS} />
        </>
    )

}
