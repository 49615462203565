export enum ApiPath {
    AUTOLOGIN = '/autologin',
    SIGN_IN = '/signIn',
    SIGN_OUT = '/signOut',
    LEXEMES = '/lexemes',
    LEXEMES_FOR_TEST = '/lexemes/test',
    LEXEME_PAIR = '/lexemePair',
    LEXEME_PAIRS_BY_TEXT = '/lexemePairsByText',
    TRANSLATE = '/translate',
    ALL_TRANSLATIONS = '/lexemes/allTranslations',
    USER = '/user',
    TEST_COMPLETE = '/test/complete',
    DELETE_ALL_DATA = '/lexemes/deleteAll',
}

export enum ApiMethod {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
}
