import React from 'react';
import styled from 'styled-components';
import { CustomButton } from './CustomButton';

const NavButtonLabel = styled.span`
    font-size: 2.2vh;
    margin-top: 1vh;
    position: relative;
    top: -1.5vh;
    color: black;
`;

const NavButtonContainer = styled.div`
    padding: 1vh 1.3vh 1vh 1vh;
    background-color: #8dfaff;
    display: inline-block;
    border-radius: 10vh;
    border: 0.2vh solid #015d60;
    margin: 0 1vh 0 0;
`;

export interface NavButtonProps {
    label: string;
    customButtonLabel: string;
    activeNav?: boolean;
    onClick?: () => void;
}

export const NavButton = (props: NavButtonProps) => {
    if (props.activeNav) {
        return (
            <NavButtonContainer>
                <CustomButton onClick={props.onClick}  label={props.customButtonLabel} />
                <NavButtonLabel>{props.label}</NavButtonLabel>
            </NavButtonContainer>
        );
    } else {
        return (
            <CustomButton onClick={props.onClick}  label={props.customButtonLabel} />
        );
    }
}
