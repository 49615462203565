import * as React from 'react';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router';
import { TestType } from '../../enum/TestType';
import { Language } from '../../enum/Language';
import { useDictionary } from '../../hooks/useDictionary';
import { MainPageContent } from '../MainPageContent';
import { Navigation, NavigationItem } from '../Navigation';
import { AppContext } from '../RootComponent';

const DEFAULT_TEST_LENGTH = 10;

export const Tests = () => {
    const [type, setType] = useState(TestType.random);
    const [length, setLength] = useState(DEFAULT_TEST_LENGTH);
    const [startClicked, setStartClicked] = useState(false);
    const [languageToTest, setLanguageToTest] = useState(Language.CZECH);

    const context = useContext(AppContext);
    const dictionary = useDictionary();

    useEffect(() => {
        context.setShowStreakReminderOverlay(false);
    },[])

    const renderLanguageToTest = () => {
        return (
            <div className="section">
                <div className="sectionLabel">{dictionary.LANGUAGE_TO_TEST_SELECT_LABEL}</div>
                <select
                    value={languageToTest}
                    onChange={(event: ChangeEvent<HTMLSelectElement>) => setLanguageToTest(event.target.value as Language)}
                >
                    <option value={Language.ENGLISH}>{dictionary.LANGUAGE_TO_TEST_OPTION_EN}</option>
                    <option value={Language.CZECH}>{dictionary.LANGUAGE_TO_TEST_OPTION_CZ}</option>
                </select>
            </div>
        );
    }

    const renderTestType = () => {
        return (
            <div className="section">
                <div className="sectionLabel">{dictionary.TEST_TYPE_SELECT_LABEL}</div>
                <select
                    value={type}
                    onChange={(event: ChangeEvent<HTMLSelectElement>) => setType(event.target.value as TestType)}
                >
                    <option value={TestType.recent}>{dictionary.TEST_TYPE_OPTION_RECENT}</option>
                    <option value={TestType.unknown}>{dictionary.TEST_TYPE_OPTION_UNKNOWN}</option>
                    <option value={TestType.familiar}>{dictionary.TEST_TYPE_OPTION_FAMILIAR}</option>
                    <option value={TestType.known}>{dictionary.TEST_TYPE_OPTION_KNOWN}</option>
                    <option value={TestType.random}>{dictionary.TEST_TYPE_OPTION_RANDOM}</option>
                </select>
            </div>
        );
    }

    const renderTestLength = () => {
        return(
            <div className="section">
                <div className="sectionLabel">{dictionary.TEST_LENGTH_SELECT_LABEL}</div>
                <select
                    value={length}
                    onChange={(event: ChangeEvent<HTMLSelectElement>) => setLength(event.target.value as unknown as number)}
                >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                </select>
            </div>
        );
    }

    const renderStartButton = () => {
        return (
            <button className="startButton" onClick={() => setStartClicked(true)}>
                {dictionary.BUTTON_START_TEST}
            </button>
        );
    }

    if (startClicked) {
        return <Navigate to={`/test/${type}/${length}/${languageToTest}`} />;
    } else {
        return (
            <>
                <h1>{dictionary.PAGETITLE_TESTS}</h1>
                <MainPageContent>
                    <div className="section">
                        {renderTestType()}
                        {renderTestLength()}
                        {renderLanguageToTest()}
                        {renderStartButton()}
                    </div>
                </MainPageContent>
                <Navigation activeItem={NavigationItem.TESTS}/>
            </>
        );
    }
}
