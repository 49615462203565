import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { ILexemePair } from '../../design/ILexemePair';
import { Language } from '../../enum/Language';
import { LexemePairWithLang } from '../../valueobject/IdPairWithLanguage';
import { AppContext } from '../RootComponent';

interface ILexemePairProps {
    data: ILexemePair;
}

export const LexemePairRow = (props: ILexemePairProps) => {
    const context = React.useContext(AppContext);
    const navigate = useNavigate();

    const onLexemePairClicked = (event: React.MouseEvent) => {
        // @ts-ignore
        const enId = event.currentTarget.attributes['data-en_id'].value;
        // @ts-ignore
        const czId = event.currentTarget.attributes['data-cz_id'].value;
        // @ts-ignore
        const langToFocus: Language = event.currentTarget.attributes['data-lang_to_focus'].value;

        context.setCurrentlyEditingLexemePair({
            czechLexeme: props.data.czechLexeme,
            englishLexeme: props.data.englishLexeme,
            langToFocus
        });

        navigate('/edit');
    }

    return (
        <tr className={props.data.familiarity}>
            <td
                onClick={onLexemePairClicked}
                data-en_id={props.data.englishLexeme.id}
                data-cz_id={props.data.czechLexeme.id}
                data-lang_to_focus={Language.ENGLISH}
            >
                {props.data.englishLexeme.text}
            </td>
            <td
                onClick={onLexemePairClicked}
                data-en_id={props.data.englishLexeme.id}
                data-cz_id={props.data.czechLexeme.id}
                data-lang_to_focus={Language.CZECH}
            >
                {props.data.czechLexeme.text}
            </td>
        </tr>
    );
}
