export enum WordType {
    ADJECTIVE = 'ADJECTIVE',
    ADVERB = 'ADVERB',
    CONJUNCTION = 'CONJUNCTION',
    GERUND = 'GERUND',
    NOUN = 'NOUN',
    PREPOSITION = 'PREPOSITION',
    PRONOUN = 'PRONOUN',
    VERB = 'VERB',
    UNKNOWN = 'UNKNOWN'
}
