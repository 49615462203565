import * as React from 'react';
import { useParams } from 'react-router-dom';

type ErrorPageParams = {
    errorCode: string;
}

export const ErrorPage = () => {
    const {errorCode} = useParams<ErrorPageParams>();

    return (
        <div>
            <h1>Something went wrong</h1>
            <h2>Error code: {errorCode}</h2>
        </div>
    );
}
