import * as Sentry from "@sentry/react";

export class Logger {

    static log = (message: string, ...args: any) => {
        console.log(message, ...args);
    }

    static error = (message: string, ...args: any) => {
        console.log('Sentry capturing message');
        process.env.DEV_MODE === "false" && Sentry.captureMessage(message, 'error');
        console.error(message, ...args);
    }

    static warn = (message: string, ...args: any) => {
        console.warn(message, ...args);
    }

    static info = (message: string, ...args: any) => {
        console.info(message, ...args);
    }

    static debug = (message: string, ...args: any) => {
        console.debug(message, ...args);
    }

    static exception = (e: Error) => {
        process.env.DEV_MODE === "false" && Sentry.captureException(e);
        Logger.error(e.message, e);
    }
}
