import { ILexeme } from "../valueobject/LexemeBase"
import { ParseError, ResultLexemes } from "../util/Api"
import { IEnglishLexeme } from "../valueobject/EnglishLexeme"
import { ICzechLexeme } from "../valueobject/CzechLexeme"
import LexemeParser from "./LexemeParser"
import { Logger } from "../util/Logger"

export default class LexemeCollectionParser {
    public static parse(data: any): ResultLexemes<IEnglishLexeme | ICzechLexeme> {
        try {
            if (!data) return [undefined, ParseError.LEXEMES];
            const output: Array<ILexeme> = [];
            const isCzech = data[0]?.hasOwnProperty('verbAspect');
            for (const item of data){
                const [result, error] = isCzech
                    ? LexemeParser.parseCzech(item)
                    : LexemeParser.parseEnglish(item);
                if (error) {
                    Logger.error(`[LexemeCollectionParser.parse] ${isCzech? "Czech" : "English"} Lexeme: ${error}`);
                    return [undefined, error];
                }
                output.push(result);
            }
            return [output, undefined];
        } catch (e) {
            Logger.error(`[LexemeCollectionParser.parse]: ${e}`);
            return [undefined, ParseError.LEXEMES];
        }
    }
}
