import { IDictionary } from '../design/IDictionary';

export const Cz: IDictionary = {
    ALERT_LEXEME_PAIR_ALREADY_EXISTS: 'Toto slovo nebo slovní spojení bylo již přidáno do vašeho slovníku dne ',
    APP_TITLE: 'CZAPP',
    APP_SUBTITLE: 'Vaše osobní slovník pro trénink',
    APP_SUBTITLE_2: 'Přihlaste se prosím pomocí účtu Google',
    BUTTON_ADD_CZECH: 'Přidat do češtiny',
    BUTTON_ADD_ENGLISH: 'Přidat do angličtiny',
    BUTTON_BACK_TO_TESTS: 'Zpět na testy',
    BUTTON_CLEAR: 'Vymazat',
    BUTTON_DELETE: 'Odstranit',
    BUTTON_DELETE_ALL_USER_DATA: 'Odstranění účtu a všech dat',
    BUTTON_EXPORT_CSV: 'Exportovat CSV',
    BUTTON_HIDE_METADATA: 'skrýt metadata',
    BUTTON_RATE_AS_FAMILIAR: 'Povědomý',
    BUTTON_RATE_AS_KNOWN: 'Známý!',
    BUTTON_RATE_AS_UNKNOWN: 'Neznámý',
    BUTTON_RECENT: 'Nedávné slova',
    BUTTON_REVEAL_ANSWER: "Ukazit odpoved",
    BUTTON_SAVE: 'Uložit',
    BUTTON_SHOW_METADATA: 'zobrazit metadata',
    BUTTON_SIGN_OUT: "Odhlásit se",
    BUTTON_START_AGAIN: 'Začít znovu',
    BUTTON_START_TEST: 'Zahajte test',
    BUTTON_SUBSCRIBE_PUSH: 'Přihlásit se k denním připomenutím',
    BUTTON_UNSUBSCRIBE_PUSH: 'Odhlásit se z denních připomenutí',
    BUTTON_UPDATE: 'Aktualizovat',
    BUTTON_SUGGEST_TRANSLATION: 'Navrhnout překlad',
    BUTTON_SWITCH_LANGUAGES: 'Přepínejte jazyky',
    BUTTON_TRANSLATE: 'Přeložit',
    CHECKBOX_REMEMBER_ME: 'Pamatovat si mě',
    CONFIRM_DELETE_LEXEME_PAIR: 'Opravdu chcete odstranit toto slovní spojení?',
    CONFIRM_WORD_ADDED: 'Slovo přidáno',
    CONFIRM_WORD_UPDATED: 'Slovník aktualizován',
    CZ_GENDER_OPTION_FEMININE: 'ženský',
    CZ_GENDER_OPTION_MASCULINE: 'mužský',
    CZ_GENDER_OPTION_MASCULINE_ANIMATUM: 'mužské živé',
    CZ_GENDER_OPTION_NEUTER: 'neutrum',
    CZ_GENDER_SELECT_LABEL: 'Rod',
    CZ_VERB_ASPECT_OPTION_IMPERFECTIVE: 'imperfektivní',
    CZ_VERB_ASPECT_OPTION_PERFECTIVE: 'perfektivní',
    CZ_VERB_ASPECT_SELECT_LABEL: 'Český slovesný aspekt',
    INLINE_ERROR_MSG_TRANSLATIONS_UNAVAILABLE: 'překlad není k dispozice',
    LANGUAGE_TO_TEST_OPTION_CZ: 'Český',
    LANGUAGE_TO_TEST_OPTION_EN: 'Anglický',
    LANGUAGE_TO_TEST_SELECT_LABEL: 'Jazyk k testování',
    LEXEME_TYPE_OPTION_PHRASE: 'fráze',
    LEXEME_TYPE_OPTION_UNKNOWN: 'neznámý',
    LEXEME_TYPE_OPTION_WORD: 'slovo',
    MESSAGE_DICTIONARY_EMPTY: 'Slovník prázdný',
    PAGETITLE_DELETE_ALL_DATA: 'Odstranit všechna data',
    PAGETITLE_RECENT: 'Nedávné',
    PAGETITLE_ADD: 'Přidat',
    PAGETITLE_EDIT: 'Upravit',
    PAGETITLE_TESTING: 'Testování...',
    PAGETITLE_TESTS: 'Zkoušky',
    PAGETITLE_SETTINGS: 'Nastavení',
    PHRASE_TYPE_OPTION_COLLOQUIALISM: 'hovorový hovor',
    PHRASE_TYPE_OPTION_IDIOM : 'idiom',
    PHRASE_TYPE_OPTION_OTHER: 'jiný',
    PHRASE_TYPE_OPTION_PROVERB: 'přísloví',
    PHRASE_TYPE_OPTION_UNKNOWN: 'neznámý',
    PHRASE_TYPE_SELECT_LABEL: 'Typ fráze',
    PLACEHOLDER_INPUT_IN_CZECH: 'Český text',
    PLACEHOLDER_INPUT_IN_ENGLISH: 'Anglický text',
    PLACEHOLDER_TRANSLATION: 'Navrhovaný překlad',
    PLACEHOLDER_TRANSLATION_LOADING: 'Načítání překladu...',
    RECORDING_INSTRUCTIONS: 'Klepnutím zastavíte',
    REMINDER_FREQUENCY_NEVER: 'Nikdy',
    REMINDER_FREQUENCY_DAILY: 'Denně',
    REMINDER_FREQUENCY_ALWAYS: 'Vždy',
    RESULTS_SUMMARY_CURRENT_STREAK: 'Aktuální série',
    RESULTS_SUMMARY_HIGH_STREAK: 'Vysoká série',
    RESULTS_SUMMARY_HIGH_STREAK_END_DATE: 'Konec v vysoké sérii',
    RESULTS_SUMMARY_TITLE: 'Výsledky',
    RESULTS_SUMMARY_ITEM_WORSE: 'Horší',
    RESULTS_SUMMARY_ITEM_NO_PROGRESS: 'Žádný pokrok',
    RESULTS_SUMMARY_ITEM_IMPROVED: 'Vylepšeno',
    RESULTS_SUMMARY_TESTS_COMPLETED_COUNT: 'Počet dokončených testů',
    SECTION_PRE_EXISTING_TRANSLATIONS: 'Již uložené překlady',
    SELECT_LABEL_LEXEME_TYPE: 'Druh lexemu',
    SETTINGS_LANGUAGE_OPTION_CZ: 'Čeština',
    SETTINGS_LANGUAGE_OPTION_EN: 'Angličtina',
    SETTINGS_SECTION_LEARNING_LANGUAGE: 'Jazyk k učení',
    SETTINGS_SECTION_TEST_REMINDER_FREQUENCY: 'četnost připomínek testů',
    SETTINGS_SECTION_UI_LANGUAGE: 'Jazyk uživatelského rozhraní',
    START_TEST_BUTTON: 'Spuštění testu',
    STREAK_CURRENT: 'Aktuální pruh',
    STREAK_MAX: 'Vysoká pruh',
    STREAK_MISSED_DAYS: "Dny zmeškané od posledního testu",
    TESTS_COMPLETED: 'Dokončené testy',
    TEST_LENGTH_SELECT_LABEL: 'Délka testu',
    TEST_TYPE_OPTION_FAMILIAR: 'Povědomý',
    TEST_TYPE_OPTION_KNOWN: 'Známý',
    TEST_TYPE_OPTION_RANDOM: 'Náhodný',
    TEST_TYPE_OPTION_RECENT: 'Poslední',
    TEST_TYPE_OPTION_UNKNOWN: 'Neznámý',
    TEST_TYPE_SELECT_LABEL: 'Typ zkoušky',
    TEXT_DATA_DELETED: 'Vaše data byla smazána. Aplikaci můžete začít znovu používat, ale musíte začít od začátku.',
    TEXT_DELETE_ALL_DATA_CONFIRMATION: 'Opravdu chcete smazat všechna data? Tato akce je nevratná.',
    TEXT_DELETE_ALL_DATA_CONFIRMATION_2 : 'Před odstraněním všech slov a frází z aplikace si můžete stáhnout soubor CSV obsahující vaše slova a fráze.',
    TEXT_DELETE_ALL_DATA_CONFIRMATION_3: 'Abyste zabránili náhodnému smazání, napište do níže uvedeného pole "delete" a poté stiskněte červené tlačítko pro odstranění dat.',
    WORD_TYPE_OPTION_ADJECTIVE: 'přídavné jméno',
    WORD_TYPE_OPTION_ADVERB: 'adverb',
    WORD_TYPE_OPTION_CONJUNCTION: 'spojení',
    WORD_TYPE_OPTION_GERUND: 'gerundium',
    WORD_TYPE_OPTION_NOUN: 'podstatné jméno',
    WORD_TYPE_OPTION_PREPOSITION: 'předložka',
    WORD_TYPE_OPTION_PRONOUN: 'příslovce',
    WORD_TYPE_OPTION_VERB: 'sloveso',
    WORD_TYPE_SELECT_LABEL: 'Typ slova'
};
