import React from "react"
import styled from 'styled-components';
import { Language } from '../../enum/Language';

const EN_FLAG_IMG_URL: string = "/src/image/icon/flag_uk.svg";
const CZ_FLAG_IMG_URL: string = "/src/image/icon/flag_cz.svg";

interface FlagDivProps {
    $url: string;
    $top?: string;
    $left?: string;
}

const FlagDiv = styled.div<FlagDivProps>`
    height: 8vw;
    width: 15vw;
    background-image: url(${(props) => props.$url});
    background-size: cover;
    background-position: center;
    position: relative;
    border-radius: 0.5vw;
    border: 0.5vw solid #8c8c8c;
    top: ${(props) => props.$top ? props.$top : '0'};
    left: ${(props) => props.$left ? props.$left : '0'};
`;

export interface LanguageIdentifierProps {
    language: Language
    $top?: string;
    $left?: string;
}

export const LanguageIdentifier = (props: LanguageIdentifierProps) => {
    return (props.language === Language.ENGLISH)
        ? <FlagDiv $url={EN_FLAG_IMG_URL} $top={props.$top} $left={props.$left} />
        : <FlagDiv $url={CZ_FLAG_IMG_URL} $top={props.$top} $left={props.$left} />
}
