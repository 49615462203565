export enum LS {
    GOOGLE_TOKEN = 'gauthToken-czapp',
    TEST_REMINDER_LAST_SHOWN_DATE = 'testReminderLastShownDate',
    DEVICE_TEST_REMINDER_FREQUENCY = 'deviceTestReminderFrequency', // cast to ReminderFrequency enum
}

export default class LocalStorage {

    public static setKey(key: LS, value: string): void {
        window.localStorage.setItem(key, value);
    }

    public static getKey(key: LS, defaultValue: string | null): string | null{
        const value = window.localStorage.getItem(key);
        return value ?? defaultValue;

    }

    public static removeKey(key: LS): void {
        window.localStorage.removeItem(key);
    }

    public static setCredential(credential: string): void {
        window.localStorage.setItem(LS.GOOGLE_TOKEN, credential);
    }

    public static clearCredential(): void {
        window.localStorage.removeItem(LS.GOOGLE_TOKEN);
    }

    public static getCredential(): string {
        const credential = window.localStorage.getItem(LS.GOOGLE_TOKEN);
        return credential ?? "";
    }

}
