import { useContext, useRef } from 'react';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useDictionary } from '../hooks/useDictionary';
import { Api } from '../util/Api';
import { GoogleAuth } from '../util/GoogleAuth';
import { AppContext } from './RootComponent';

const StyledParentDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
`
const SignInText = styled.div`
    font-size: 4vw;
    margin-top: 5vh;
`;
const AppTitle = styled.div`
    font-size: 3em;
    margin: 2em 1em;
`;
const SubtitleText = styled.div`
    font-size: 1.5em;
    margin-bottom: 7em;
    text-align: center;
`;
const RememberMeCheckbox = styled.div`
    margin-bottom: 1em;
`;

export const SignInPage = () => {

    const context = useContext(AppContext);
    const dictionary = useDictionary();
    const [isRememberMeChecked, setIsRememberMeChecked] = React.useState(false);
    const gAuthButtonRef = useRef<HTMLDivElement>(document.createElement('div'))
    const navigate = useNavigate();

    React.useEffect(() => {
        if (process.env.DEV_MODE === 'true') return;
        GoogleAuth.signIn(gAuthButtonRef.current).then(
            async (signInResponse) => {
                if (signInResponse.credential) {
                    const userData = await Api.signIn(isRememberMeChecked);
                    if (!userData) {
                        // TODO - is this the best way to handle this?
                        console.log("Failed to sign in");
                        return;
                    }
                    context.setLoggedInUser(userData);
                    if (window.history?.length && window.history.length > 1) {
                        navigate(-1);
                    } else {
                        navigate('/', { replace: true });
                    }
                }
            }
        ).catch(
            (response: any) => console.log(response)
        );
    }, [isRememberMeChecked]);

    return (
        <StyledParentDiv className="page signInPage">
            <AppTitle>{dictionary.APP_TITLE}</AppTitle>
            <SubtitleText>{dictionary.APP_SUBTITLE}</SubtitleText>
            <RememberMeCheckbox>
                <input type="checkbox" onChange={(event) => {
                    setIsRememberMeChecked(event.target.checked);
                }}/>
                {dictionary.CHECKBOX_REMEMBER_ME}
            </RememberMeCheckbox>
            <div id="googleSignInButton" ref={gAuthButtonRef}></div>
            <SignInText className="appSubtitle">{dictionary.APP_SUBTITLE_2}</SignInText>
        </StyledParentDiv>
    );
};
