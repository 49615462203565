import { ParseError, ResultLexemePair } from '../util/Api';
import { Logger } from '../util/Logger';
import CzechLexeme from '../valueobject/CzechLexeme';
import EnglishLexeme from '../valueobject/EnglishLexeme';
import LexemePair from '../valueobject/LexemePair';

export default class LexemePairParser {

    public static parse(data: any): ResultLexemePair {

        try {
            const czechLexeme = new CzechLexeme();

            czechLexeme.id = data.cz_id;
            czechLexeme.text = data.cz_text;
            czechLexeme.notes = data.cz_notes;
            czechLexeme.type = data.cz_type;
            czechLexeme.wordType = data.cz_wordType;
            czechLexeme.phraseType = data.cz_phraseType;
            czechLexeme.id = data.cz_id;
            czechLexeme.dateAdded = data.cz_ts;
            czechLexeme.userId = data.cz_userId;
            czechLexeme.gender = data.cz_gender;
            czechLexeme.verbAspect = data.cz_verbAspect;

            const englishLexeme = new EnglishLexeme();
            englishLexeme.id = data.en_id;
            englishLexeme.text = data.en_text;
            englishLexeme.notes = data.en_notes;
            englishLexeme.type = data.en_type;
            englishLexeme.wordType = data.en_wordType;
            englishLexeme.phraseType = data.en_phraseType;
            englishLexeme.id = data.en_id;
            englishLexeme.dateAdded = data.en_ts;
            englishLexeme.userId = data.en_userId;

            const output = new LexemePair(englishLexeme, czechLexeme);
            output.dateAdded = data.dateAdded;
            output.notes = data.notes;
            output.userId = data.userId;
            output.familiarity = data.familiarity;
            output.lastTested = data.lastTested;

            return [output, undefined];

        } catch (e) {
            Logger.error(`[LexemePairParser.parse]: ${e}`);
            return [undefined, ParseError.LEXEME_PAIR];
        }
    }
}
