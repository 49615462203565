import LexemeBase, { ILexeme } from './LexemeBase';
import { CzVerbAspect } from '../enum/CzVerbAspect';
import { CzGender } from '../enum/CzGender';

export interface ICzechLexeme extends ILexeme {
    gender: CzGender;
    verbAspect: CzVerbAspect;
}

export default class CzechLexeme extends LexemeBase implements ICzechLexeme {
    constructor(text: string = "", id: number | null = null) {
        super(text, id);
    }
    public gender: CzGender = CzGender.UNKNOWN;
    public verbAspect: CzVerbAspect = CzVerbAspect.UNKNOWN;
}
