import { useEffect } from 'react';
import * as React from 'react';
import CsvDownloader from 'react-csv-downloader';
import { Datas } from 'react-csv-downloader/dist/cjs/lib/csv';
import { useDictionary } from '../../hooks/useDictionary';
import LocalStorage from '../../util/LocalStorage';
import { getFormattedDate } from '../../util/Utils';
import { Navigation, NavigationItem } from '../Navigation';

export const ExportCsv = () => {

    const [datas, setDatas] = React.useState<Datas>({} as Datas);
    const dictionary = useDictionary()

    useEffect(() => {
        fetch(process.env.CZAPP_SERVER_BASE_URL + '/lexemes/all', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': LocalStorage.getCredential() as string
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data.queryData === undefined) {
                    return;
                }
                const dataRows = [] as Datas;
                data.queryData.forEach((element: any) => {
                    dataRows.push({
                        en: element.en_text,
                        cz: element.cz_text,
                        dateAdded: element.dateAdded.substring(0, element.dateAdded.indexOf('T'))
                    });
                })
                setDatas(dataRows);
            });
    }, []);

    const columns = [{
        id: 'en',
        displayName: 'EN'
    }, {
        id: 'cz',
        displayName: 'CZ'
    }, {
        id: 'dateAdded',
        displayName: 'Date Added'
    }];

    return (
        <>
            <CsvDownloader
                filename={`czapp-export-${getFormattedDate(new Date())}`}
                extension=".csv"
                separator=","
                wrapColumnChar='"'
                columns={columns}
                datas={datas}
                text={dictionary.BUTTON_EXPORT_CSV}
            />
            <Navigation activeItem={NavigationItem.SETTINGS} />
        </>
    );
}
