import { ParseError, ResultLexeme } from "../util/Api";
import { Logger } from "../util/Logger";
import CzechLexeme, { ICzechLexeme } from "../valueobject/CzechLexeme";
import EnglishLexeme, { IEnglishLexeme } from "../valueobject/EnglishLexeme";

export default class LexemeParser {
    public static parseCzech(data: any): ResultLexeme<ICzechLexeme>  {
        try {
            const czechLexeme = new CzechLexeme();
            czechLexeme.id = data.id;
            czechLexeme.text = data.text;
            czechLexeme.notes = data.notes;
            czechLexeme.type = data.type;
            czechLexeme.wordType = data.wordType;
            czechLexeme.phraseType = data.phraseType;
            czechLexeme.verbAspect = data.verbAspect;
            czechLexeme.gender = data.gender;
            czechLexeme.dateAdded = data.ts;
            czechLexeme.userId = data.userId;
            return [czechLexeme, undefined];

        } catch (e) {
            Logger.error(`[LexemeParser.parse]: ${e}`);
            return [undefined, ParseError.LEXEME_CZECH];
        }
    }

    public static parseEnglish(data: any): ResultLexeme<IEnglishLexeme> {
        try {
            const englishLexeme = new EnglishLexeme();
            englishLexeme.id = data.id;
            englishLexeme.text = data.text;
            englishLexeme.notes = data.notes;
            englishLexeme.type = data.type;
            englishLexeme.wordType = data.wordType;
            englishLexeme.phraseType = data.phraseType;
            englishLexeme.dateAdded = data.ts;
            englishLexeme.userId = data.userId;
            return [englishLexeme, undefined];
        } catch (e) {
            Logger.error(`[LexemeParser.parse]: ${e}`);
            return [undefined, ParseError.LEXEME_ENGLISH];
        }
    }
}
