import * as React from 'react';
import { useEffect, useState } from 'react';
import { ILexemePair } from '../../design/ILexemePair';
import { useDictionary } from '../../hooks/useDictionary';
import { Api, AppError } from '../../util/Api';
import { EmptyDictionary } from '../generic/EmptyDictionary';
import { Navigation, NavigationItem } from '../Navigation';
import { LexemePairRow } from './LexemePairRow';
import { Navigate } from 'react-router';

const DEFAULT_PAGE = 1;
const DEFAULT_PAGE_LENGTH = 50;

export const Recents = () => {

    const [data, setData] = useState<ILexemePair[]>(null as unknown as ILexemePair[]);
    const [page, setPage] = useState<number>(DEFAULT_PAGE);
    const [error, setError] = useState<AppError>(null as unknown as AppError);
    const [isLoadingNextPage, setIsLoadingNextPage] = useState<boolean>(false);
    const [endOfData, setEndOfData] = useState<boolean>(false);

    const addButtonRef = React.createRef<HTMLButtonElement>();
    const dictionary = useDictionary();

    useEffect( () => {
        loadLexemePairs();
    }, []);

    useEffect(() => {
        if (addButtonRef.current) addButtonRef.current.focus();
    }, [data]);

    const onBodyScrolled = async (e: React.UIEvent<HTMLTableSectionElement>) => {
        const body = e.currentTarget;
        if (body.scrollTop + body.clientHeight >= body.scrollHeight) {
            if (!isLoadingNextPage && !endOfData) {
                setIsLoadingNextPage(true);
                await loadLexemePairs();
                setIsLoadingNextPage(false);
            }
        }
    }

    const loadLexemePairs = async () => {
        const [result, error] = await Api.getLexemes(page, DEFAULT_PAGE_LENGTH);
        setPage(page + 1);
        if (error) {
            // TODO - handle error more gracefully than this
            setError(error);
        } else {
            const newData = data ? data.concat(result) : result;
            setData(newData);
            if (result.length < DEFAULT_PAGE_LENGTH) {
                setEndOfData(true);
            }
        }
    }

    if (error)
        return <Navigate to={`/errorPage/${error}`} replace={true}/>;

    if (!data)
        return null;

    return (
        <>
            <h1>{dictionary.PAGETITLE_RECENT}</h1>
            {data.length > 0 && <table className="recentsTable">
                <tbody onScroll={onBodyScrolled}>
                {data.map((recent, index) => <LexemePairRow data={recent} key={index} />)}
                </tbody>
            </table>}
            {data.length === 0 && <EmptyDictionary />}
            <Navigation activeItem={NavigationItem.RECENT} />
        </>
    );
}
