import React from "react"
import styled from 'styled-components';

const LogoImage = styled.img`
    background-image: url('/src/image/logo.png');
    background-size: cover;
    background-position: center;
    position: relative;
    border-radius: 0.5vw;
    border: 0.5vw solid #8c8c8c;
    height: 16vw;
    width: 39vw;
    display: block;
    margin: 7vh auto;
`;

export const Logo = () => {
    return (
        <LogoImage />
    )
}
