import React, { useContext } from 'react';
import { AppContext } from '../components/RootComponent';
import { IDictionary } from '../design/IDictionary';
import { Language } from '../enum/Language';
import { Cz } from '../valueobject/Cz';
import { En } from '../valueobject/En';

export const useDictionary = (): IDictionary => {
    const context = useContext(AppContext);
    return context.uiLanguage === Language.ENGLISH ? En : Cz;
}
