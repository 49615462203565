import React from 'react';
import { useContext } from 'react';
import { Navigate } from "react-router-dom";
import { AppContext } from './RootComponent';

export const ProtectedRoute = ({ children }:any) => {
    const context = useContext(AppContext)

    if (!context.loggedInUser) {
        return <Navigate to="/sign-in" />;
    }
    return children;
};
