import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { RootComponent } from './components/RootComponent';
import { BrowserRouter } from 'react-router-dom';
import './style/global.css';
import * as Sentry from "@sentry/react";

declare global {
    interface Window {
        google: any;
        handleCredentialResponse?: any;
        OneSignalDeferred?: any;
    }
}

Sentry.init({
    // SENTRY_DSN and SENTRY_ENVIRONMENT are set in .env file but don't get picked up automatically by
    // the build process (because of Webpack), so we need to set them here manually.
    dsn: process.env.SENTRY_DSN,
    environment: process.env.SENTRY_ENVIRONMENT,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: parseInt(process.env.SENTRY_TRACING_SAMPLE_RATE as string),
    tracePropagationTargets: [process.env.CZAPP_SERVER_BASE_URL as string],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const FallbackComponent = () => (
    <div>
        <h1>Something went wrong</h1>
        <p>We are sorry for the inconvenience. Please try again later.</p>
    </div>
);

window.onload = function () {
    const container = document.getElementById('root');
    const root = createRoot(container!);
    root.render(
        <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
            <BrowserRouter>
                <RootComponent/>
            </BrowserRouter>
        </Sentry.ErrorBoundary>
    );
}

