import React, { useContext } from 'react';
import { Route, Routes } from 'react-router';
import { AddNu } from './add/AddNu';
import { Edit } from './edit/Edit';
import { ErrorPage } from './error/ErrorPage';
import { Navigation, NavigationItem } from './Navigation';
import { ProtectedRoute } from './ProtectedRoute';
import { Recents } from './recent/Recents';
import { AppContext } from './RootComponent';
import { DeleteAllUserData } from './settings/DeleteAllUserData';
import { ExportCsv } from './settings/ExportCsv';
import { Settings } from './settings/Settings';
import { SignInPage } from './SignInPage';
import { StreakReminder } from './StreakReminder';
import { Test } from './tests/Test';
import { Tests } from './tests/Tests';

export const App = () => {

    const context = useContext(AppContext);

    return (
        <div className="app">
            <div className="pageLayer">
                <Routes>
                    <Route path="/settings" element={<ProtectedRoute><Settings/></ProtectedRoute>}/>
                    <Route path="/add" element={<ProtectedRoute><AddNu/></ProtectedRoute>}/>
                    <Route path="/edit" element={<ProtectedRoute><Edit/></ProtectedRoute>}/>
                    <Route path="/tests" element={<ProtectedRoute><Tests/></ProtectedRoute>}/>
                    <Route path="/test/:type/:length/:languageToTest" element={<ProtectedRoute><Test/></ProtectedRoute>}/>
                    <Route path="/recent" element={<ProtectedRoute><Recents/></ProtectedRoute>}/>
                    <Route path="/export-csv" element={<ProtectedRoute><ExportCsv/></ProtectedRoute>}/>
                    <Route path="/delete-all-user-data" element={<ProtectedRoute><DeleteAllUserData/></ProtectedRoute>}/>
                    <Route path="/errorPage/:errorCode" element={<ProtectedRoute><ErrorPage/></ProtectedRoute>}/>
                    <Route path="/sign-in" element={<SignInPage/>}/>
                    <Route path="/" element={<ProtectedRoute><AddNu/><Navigation activeItem={NavigationItem.ADD} /></ProtectedRoute>}/>
                </Routes>
            </div>
            {process.env.DEV_MODE === 'true' && (<div id='devMode'>DEV MODE</div>)}
            {context.showStreakReminderOverlay && <StreakReminder/>}
        </div>
    );
}
