import { ILexemePair } from '../design/ILexemePair';
import { ParseError, ResultLexemePairs } from '../util/Api';
import { Logger } from '../util/Logger';
import LexemePairParser from './LexemePairParser';

export default class LexemePairCollectionParser {

    public static parse(data: any): ResultLexemePairs {
        try {
            if (!data) return [undefined, ParseError.LEXEME_PAIRS];
            const output: Array<ILexemePair> = [];
            for (const item of data){
                const [parsedLexeme, lexemeParserError] = LexemePairParser.parse(item);
                if (lexemeParserError) {
                    Logger.error(`[LexemePairCollectionParser.parse]: ${lexemeParserError}`);
                    return [undefined, lexemeParserError];
                }
                output.push(parsedLexeme);
            }
            return [output, undefined];
        } catch (e) {
            Logger.error(`[LexemePairCollectionParser.parse]: ${e}`);
            return [undefined, ParseError.LEXEME_PAIRS];
        }
    }
}
