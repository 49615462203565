import React from 'react';
import { Language } from '../../enum/Language';
import { InputArea } from './InputArea';
import { LanguageIdentifier } from './LanguageIdentifier';

export interface InputWithLanguageFlagProps {
    inputText: string;
    inputLanguage: Language;
    onInputTextChanged: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onClearInputTextButtonClicked: () => void;
    placeholder?: string;
}

export const InputWithLanguageFlag = (props: InputWithLanguageFlagProps) => {
    return (
        <>
            <LanguageIdentifier
                language={props.inputLanguage}
                $top="7vw"
                $left="3vw"
            />
            <InputArea
                value={props.inputText}
                onChange={props.onInputTextChanged}
                onClear={props.onClearInputTextButtonClicked}
            />
        </>
    );
}
