import React from 'react';
import styled from 'styled-components';

interface MainPageContentProps {
    children: React.ReactNode;
}
const ScrollingContentContainer = styled.div`
    overflow-y: auto;
    height: 72vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-bottom: 1px solid #8c8c8c;
`;
const InnerContainer = styled.div`
    padding-bottom: 40vh;
`

export const MainPageContent = (props: MainPageContentProps) => {
    return (
        <ScrollingContentContainer>
            <InnerContainer>
                {props.children}
            </InnerContainer>
        </ScrollingContentContainer>
    )
}
