import * as React from 'react';
import { useContext, useEffect } from 'react';
import { Navigate } from 'react-router';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Language } from '../../enum/Language';
import { ReminderFrequency } from '../../enum/ReminderFrequency';
import { useDictionary } from '../../hooks/useDictionary';
import { Api } from '../../util/Api';
import { GoogleAuth } from '../../util/GoogleAuth';
import LocalStorage, { LS } from '../../util/LocalStorage';
import { MainPageContent } from '../MainPageContent';
import { Navigation, NavigationItem } from '../Navigation';
import { AppContext } from '../RootComponent';

const SettingsSection= styled.div`
    margin: 4.5vh 0;
    font-size: 4vh;
    .sectionTitle {
        margin-bottom: 2vh;
    }
    .radio {
        margin: 8px;
    }
`

export const Settings = () => {
    const context = useContext(AppContext);
    const [exportCsvClicked, setExportCsvClicked] = React.useState<boolean>(false);
    const [deleteAllUserDataClicked, setDeleteAllUserDataClicked] = React.useState<boolean>(false);
    const [reminderFrequency, setReminderFrequency] = React.useState<ReminderFrequency | null>(null);
    const navigate = useNavigate()
    const dictionary = useDictionary();

    useEffect(() => {
        setReminderFrequency(LocalStorage.getKey(LS.DEVICE_TEST_REMINDER_FREQUENCY, 'daily') as ReminderFrequency);
        if (context.oneSignalInitialized) {
            return;
        }
        window.OneSignalDeferred = window.OneSignalDeferred || [];
        window.OneSignalDeferred.push(function(OneSignal: any) {
            OneSignal.init({
                appId: process.env.ONESIGNAL_APP_ID,
                safari_web_id: process.env.ONESIGNAL_SAFARI_WEB_ID,
                allowLocalhostAsSecureOrigin: true,
                promptOptions: {
                    customlink: {
                        enabled: true, /* Required to use the Custom Link */
                        style: "button", /* Has value of 'button' or 'link' */
                        size: "medium", /* One of 'small', 'medium', or 'large' */
                        color: {
                            button: '#5f803f', /* Color of the button background if style = "button" */
                            text: '#FFFFFF', /* Color of the prompt's text */
                        },
                        text: {
                            subscribe: dictionary.BUTTON_SUBSCRIBE_PUSH, /* Prompt's text when not subscribed */
                            unsubscribe: dictionary.BUTTON_UNSUBSCRIBE_PUSH, /* Prompt's text when subscribed */
                        },
                        unsubscribeEnabled: true, /* Controls whether the prompt is visible after subscription */
                    }
                }
            });
        });
        const oneSignalScript = document.createElement('script');
        oneSignalScript.src = 'https://cdn.onesignal.com/sdks/web/v16/OneSignalSDK.page.js';
        oneSignalScript.async = true;
        oneSignalScript.defer = true;
        document.head.appendChild(oneSignalScript);
        context.setOneSignalInitialized(true);
    }, []);

    useEffect(() => {
        LocalStorage.setKey(LS.DEVICE_TEST_REMINDER_FREQUENCY, reminderFrequency as string);
    }, [reminderFrequency]);

    const onSignOutClicked = async () => {
        await Api.signOut();
        await GoogleAuth.signOut();
        context.setLoggedInUser(undefined);
        navigate(0);
    }

    const onExportCsvClicked = () => {
        setExportCsvClicked(true);
    }

    const onDeleteAllUserDataClicked = () => {
        setDeleteAllUserDataClicked(true);
    }

    const onReminderFrequencyChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setReminderFrequency(event.target.value as ReminderFrequency);
    }

    const onLearningLanguageChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        context.setLearningLanguage(event.target.value as Language)
        Api.postUser(event.target.value as Language, context.uiLanguage)
    }

    const onUiLanguageChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        context.setUiLanguage(event.target.value as Language)
        Api.postUser(context.learningLanguage, event.target.value as Language)
    }

    const renderOneSignalPlaceholder = () => {
        return (
            <div className="settingsSection">
                <div className="onesignal-customlink-container"></div>
            </div>
        )
    }

    const renderUiLanguage = () => {
        return (
            <SettingsSection>
                <div className='sectionTitle'>
                    {dictionary.SETTINGS_SECTION_UI_LANGUAGE}
                </div>
                <div className="radio">
                    <label>
                        <input
                            type="radio"
                            value={Language.ENGLISH}
                            onChange={onUiLanguageChanged}
                            checked={context.uiLanguage === Language.ENGLISH}
                        />
                        {dictionary.SETTINGS_LANGUAGE_OPTION_EN}
                    </label>
                </div>

                <div className="radio">
                    <label>
                        <input
                            type="radio"
                            value={Language.CZECH}
                            onChange={onUiLanguageChanged}
                            checked={context.uiLanguage === Language.CZECH}
                        />
                        {dictionary.SETTINGS_LANGUAGE_OPTION_CZ}
                    </label>
                </div>
            </SettingsSection>
        );
    }

    const renderLearningLanguage = () => {
        return (
            <SettingsSection>
                <div className="sectionTitle">
                    {dictionary.SETTINGS_SECTION_LEARNING_LANGUAGE}
                </div>

                <div className="radio">
                    <label>
                        <input
                            type="radio"
                            value={Language.ENGLISH}
                            onChange={onLearningLanguageChanged}
                            checked={context.learningLanguage === Language.ENGLISH}
                        />
                        {dictionary.SETTINGS_LANGUAGE_OPTION_EN}</label>
                </div>

                <div className="radio">
                    <label>
                        <input
                            type="radio"
                            value={Language.CZECH}
                            onChange={onLearningLanguageChanged}
                            checked={context.learningLanguage === Language.CZECH}
                        />
                        {dictionary.SETTINGS_LANGUAGE_OPTION_CZ}
                    </label>
                </div>
            </SettingsSection>
        );
    }

    if (exportCsvClicked) {
        return (<Navigate to="/export-csv" />)
    }

    if (deleteAllUserDataClicked) {
        return (<Navigate to="/delete-all-user-data" />)
    }

    return (
        <>
            <h1>{dictionary.PAGETITLE_SETTINGS}</h1>
            <MainPageContent>
                <div className="settingsPage">
                    {renderLearningLanguage()}
                    {renderUiLanguage()}
                    <SettingsSection>
                        <div className='sectionTitle'>
                            {dictionary.SETTINGS_SECTION_TEST_REMINDER_FREQUENCY}
                        </div>
                        <div>
                            <label>
                                <input
                                    type="radio"
                                    value={ReminderFrequency.NEVER}
                                    onChange={onReminderFrequencyChanged}
                                    checked={reminderFrequency === ReminderFrequency.NEVER}
                                />
                                {dictionary.REMINDER_FREQUENCY_NEVER}
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value={ReminderFrequency.DAILY}
                                    onChange={onReminderFrequencyChanged}
                                    checked={reminderFrequency === ReminderFrequency.DAILY}
                                />
                                {dictionary.REMINDER_FREQUENCY_DAILY}
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value={ReminderFrequency.ALWAYS}
                                    onChange={onReminderFrequencyChanged}
                                    checked={reminderFrequency === ReminderFrequency.ALWAYS}
                                />
                                {dictionary.REMINDER_FREQUENCY_ALWAYS}
                            </label>
                        </div>
                    </SettingsSection>
                    <SettingsSection>
                        <button onClick={onExportCsvClicked}>{dictionary.BUTTON_EXPORT_CSV}</button>
                    </SettingsSection>
                    <SettingsSection>
                        <button
                            onClick={onDeleteAllUserDataClicked}>{dictionary.BUTTON_DELETE_ALL_USER_DATA}</button>
                    </SettingsSection>
                    <SettingsSection>
                        <button onClick={onSignOutClicked}>{dictionary.BUTTON_SIGN_OUT}</button>
                    </SettingsSection>
                    {renderOneSignalPlaceholder()}
                </div>
            </MainPageContent>
            <Navigation activeItem={NavigationItem.SETTINGS}/>
        </>
    );
}
