import '../style/App.css';
import { createContext, useEffect } from 'react';
import * as React from 'react';
import { Language } from '../enum/Language';
import { ReminderFrequency } from '../enum/ReminderFrequency';
import { convertJSDateObjectToMySQLDateString, getClientDate, MySQLDate } from '../util/Date';
import LocalStorage, { LS } from '../util/LocalStorage';
import { shouldShowReminder } from '../util/Streak';
import { LexemePairWithLang } from '../valueobject/IdPairWithLanguage';
import { App } from './App';
import { Api, devModeUserData, UserData } from '../util/Api';

export type AppContext = {
    learningLanguage: Language;
    setLearningLanguage: (language: Language) => void;

    uiLanguage: Language;
    setUiLanguage: (language: Language) => void;

    oneSignalInitialized: boolean;
    setOneSignalInitialized: (initialized: boolean) => void;

    loggedInUser: Partial<UserData> | undefined;
    setLoggedInUser: (user: Partial<UserData> | undefined) => void;

    showStreakReminderOverlay: boolean;
    setShowStreakReminderOverlay: (show: boolean) => void;

    currentlyEditingLexemePair: LexemePairWithLang | undefined;
    setCurrentlyEditingLexemePair: (idPairWithLang: LexemePairWithLang | undefined) => void;
}

export const AppContext = createContext<AppContext>(null as unknown as AppContext);

export const RootComponent = () => {
    const [learningLanguage, setLearningLanguage] = React.useState(Language.UNKNOWN);
    const [uiLanguage, setUiLanguage] = React.useState(Language.UNKNOWN);
    const [oneSignalInitialized, setOneSignalInitialized] = React.useState<boolean>(false);
    const [loggedInUser, setLoggedInUser] = React.useState<Partial<UserData> | undefined>( undefined);
    const [autoLoginResponded, setAutoLoginResponded] = React.useState<boolean>(false);
    const [showStreakReminderOverlay, setShowStreakReminderOverlay] = React.useState<boolean>(false);
    const [currentlyEditingLexemePair, setCurrentlyEditingLexemePair] = React.useState<LexemePairWithLang | undefined>(undefined);

    const initPage = async () => {
        if (process.env.DEV_MODE === "true") setLoggedInUser(devModeUserData);
        const clientDate: string = getClientDate(new Date());
        const user = await Api.autologin(clientDate)

        if (user){
            const {
                learningLanguage,
                uiLanguage,
                currentStreakEnd ,
                currentStreakLength,
            } = user;
            setLearningLanguage(learningLanguage!);
            setUiLanguage(uiLanguage!);

            const today: MySQLDate = convertJSDateObjectToMySQLDateString(new Date())!
            const lastShownDate: MySQLDate | null = LocalStorage.getKey(LS.TEST_REMINDER_LAST_SHOWN_DATE, null) as MySQLDate | null;
            const reminderFrequency: ReminderFrequency = LocalStorage.getKey(
                LS.DEVICE_TEST_REMINDER_FREQUENCY, ReminderFrequency.DAILY) as ReminderFrequency;

            const shouldShowReminderOptions = {
                currentStreakLength: currentStreakLength!,
                currentStreakEnd: currentStreakEnd!,
                today,
                lastShownDate,
                reminderFrequency
            };
            const showReminder = shouldShowReminder(shouldShowReminderOptions);
            if (showReminder) {
                LocalStorage.setKey(LS.TEST_REMINDER_LAST_SHOWN_DATE, today);
                setShowStreakReminderOverlay(true);
            }

            setLoggedInUser(user);
        }
        setAutoLoginResponded(true);
    }

    const onVisibilityChange = async () => {
        if (!document.hidden) {
            setAutoLoginResponded(false);
            await initPage();
        }
    }

    useEffect(() => {
        (async () => {
            await initPage();
            document.addEventListener("visibilitychange", onVisibilityChange);
        })();
        return () => {
            document.removeEventListener("visibilitychange", onVisibilityChange);
        }
    }, []);

    return (
        <AppContext.Provider value={
            {
                learningLanguage,
                setLearningLanguage,

                uiLanguage,
                setUiLanguage,

                oneSignalInitialized,
                setOneSignalInitialized,

                loggedInUser,
                setLoggedInUser,

                showStreakReminderOverlay,
                setShowStreakReminderOverlay,

                currentlyEditingLexemePair,
                setCurrentlyEditingLexemePair
            }
        }>
            {autoLoginResponded && <App />}
            {!autoLoginResponded && <p>Loading...</p>}
        </AppContext.Provider>
    );
};
