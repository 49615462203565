import { WordType } from '../enum/WordType';
import { LexemeType } from '../enum/LexemeType';
import { PhraseType } from '../enum/PhraseType';

export interface ILexeme {
    text: string;
    notes: string;
    type: LexemeType;
    wordType: WordType;
    phraseType: PhraseType;
    id: number | null;
    dateAdded: string;
    userId: string;
}

export default abstract class LexemeBase implements ILexeme {
    constructor(text: string, id: number | null = null) {
        this.text = text;
        this.id = id;
    }
    public text: string = '';
    public notes: string = '';
    public type: LexemeType = LexemeType.UNKNOWN;
    public wordType: WordType = WordType.ADJECTIVE;
    public phraseType: PhraseType = PhraseType.UNKNOWN;
    public id: number | null = null;
    public dateAdded: string = "";
    public userId: string = "";
}
