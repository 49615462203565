import { RequestError, Result } from './Api';
import LocalStorage from './LocalStorage';
import { Logger } from './Logger';

const REQUEST_TIMEOUT = 5_000;

export default class LoaderUtil {

    public static request = async<T>(path: string, method: string, body?: any): Promise<Result<T>> => {
        let response: Response;
        try {
            const controller = new AbortController();
            let requestAborted = false;

            const id = setTimeout(() => {
                Logger.error(`REQUEST TIMEOUT: ${path} ${method} timed out after ${REQUEST_TIMEOUT}ms`);
                controller.abort();
                requestAborted = true;
            }, REQUEST_TIMEOUT);

            const headers = {
                'Authorization': LocalStorage.getCredential(),
                'Content-Type': 'application/json'
            };

            response = await fetch(
                process.env.CZAPP_SERVER_BASE_URL + path,
            {
                method,
                headers,
                signal: controller.signal,
                body: JSON.stringify(body),
                credentials: 'include'
            })

            if (requestAborted) {
                Logger.warn("[request] Request aborted", response);
                return [undefined, RequestError.TIMEOUT];
            }

            clearTimeout(id);

            if (!response.ok) {
                Logger.warn("[request] Response not OK", response.statusText);

                return [undefined, {
                    status: response.status,
                    statusText: response.statusText,
                    message: response.body?.toString()
                }];
            }

            const contentType = response.headers.get("content-type");

            // If the response is HTML, return the text
            if (contentType?.includes("text/html")) {
                return [await response.text() as T, undefined];
            }

            // If the response is JSON, parse and return it
            const responseJson = await response.json() as T;
            return [responseJson, undefined];

        } catch (err) {
            Logger.error("[request] Unhandled error", err);
            return [undefined, RequestError.UNHANDLED];
        }
    }
}
